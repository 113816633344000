import { useContext, useRef, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { MapContext } from '../Contexts/MapContext';
import { LocationMarker } from './LocationMarker';

export const Map = ({ locations }) => {
  const mapRef = useRef(null);
  const [visibleLocations, setVisibleLocations] = useState([]);
  const { setMapInstance } = useContext(MapContext);

  // ? Further optimization could be to group the markers together if there are too many in one place
  // Calculate average of the coordinates and place grouping marker in place, tooltip might open a menu with spesific details
  const showMarkersInsideBoundingBox = (mapInstance) => {
    const bounds = mapInstance.getBounds();
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    const locationsInsideBoundingBox = locations.filter((loc) => {
      return (
        loc.lat > sw.lat &&
        loc.lat < ne.lat &&
        loc.long < ne.lng &&
        loc.long > sw.lng
      );
    });
    setVisibleLocations(locationsInsideBoundingBox);
    mapInstance.closePopup();
  };

  return (
    <MapContainer
      whenCreated={(mapInstance) => {
        mapRef.current = mapInstance;
        setMapInstance(mapRef);
        showMarkersInsideBoundingBox(mapInstance);
        mapInstance.on('moveend', () => {
          showMarkersInsideBoundingBox(mapInstance);
        });
      }}
      center={[locations[0].lat, locations[0].long]}
      zoom={15}
      height={100}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      {visibleLocations
        ? visibleLocations.map((loc, index) => {
            return <LocationMarker location={loc} index={index} key={index} />;
          })
        : null}
    </MapContainer>
  );
};
