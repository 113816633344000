import { Marker, Popup } from 'react-leaflet';
import moment from 'moment';

export const LocationMarker = (props) => {
  const { location } = props;
  const bearingArrowStyles = {
    transform: `rotate(${location.bearing}deg`,
  };
  return (
    <Marker position={[location.lat, location.long]}>
      <Popup>
        {location.client_id}
        <br />
        <b>{moment(Number(location.timestamp)).format('DD.MM.YYYY HH:mm')}</b>
        <br />
        <hr />
        Sijainnin tarkkuus: <b>{location.acc} m</b> <br />
        Akun varaus: <b>{location.battery_level}%</b> <br />
        Viimeksi aktiivinen:{' '}
        <b>{moment(Number(location.last_screen_unlock)).format('HH:mm:ss')}</b>
        <br />
        Nopeus: <b>{Math.floor(location.speed * 3.6)} km/h</b>
        <br />
        Suunta: <b>{location.bearing}&deg;</b>
        <div className='bearing-arrow-container'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='bearing-arrow'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
            style={bearingArrowStyles}
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={2}
              d='M5 10l7-7m0 0l7 7m-7-7v18'
            />
          </svg>
        </div>
      </Popup>
    </Marker>
  );
};
