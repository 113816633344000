import { useState } from 'react';
import {
  Button,
  Input,
  Container,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';

export const Login = ({ fetchLocations }) => {
  const [password, setPassword] = useState('');

  return (
    <Container>
      <FormControl mt={8}>
        <FormLabel htmlFor='password'>Salasana</FormLabel>
        <Input
          id='password'
          type='password'
          autoComplete='true'
          placeholder='...'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          mt={3}
          colorScheme={'blue'}
          onClick={() => fetchLocations(password)}
        >
          Kirjaudu sisään
        </Button>
      </FormControl>
    </Container>
  );
};
