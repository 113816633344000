import { Box } from '@chakra-ui/react';
import { LocationsList } from '../LocationsList';

export const Menu = ({locations}) => {

  return (
    <Box bg='#262c37' zIndex={99999} p={2} pt={3} w="100%" minH={'100%'} color='white'>
      {locations.length > 0 ? (
        <LocationsList locations={locations}/>
      ) : null}
    </Box>
  );
};
