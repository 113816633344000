import React, { createContext, useState } from "react";

export const MapContext = createContext();

export const MapContextProvider = ({ children }) => {
  const [mapInstance, setMapInstance] = useState(null);
  const [locations, setLocations] = useState(null);
  return (
    <MapContext.Provider
      value={{ mapInstance, setMapInstance, locations, setLocations }}
    >
      {children}
    </MapContext.Provider>
  );
};
