import "./App.css";
import { useState } from "react";
import { Map } from "./components/Map/Map";
import { Login } from "./components/Login/Login";
import { Menu } from "./components/Menu";
import { Flex, Box } from "@chakra-ui/react";
import { MapContextProvider } from "./components/Contexts/MapContext";

function App() {
  const [locations, setLocations] = useState(null);
  async function fetchLocations(password) {
    try {
      const data = await fetch(
        process.env.REACT_APP_API_URL +
          "/locations/" +
          process.env.REACT_APP_DEFAULT_DEVICE_ID,
        {
          headers: {
            Authorization: password,
          },
        }
      );
      setLocations(await data.json());
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <MapContextProvider>
      {locations ? (
        <Flex maxH="100vh" h={'100vh'} direction={{base: 'column-reverse', md: 'row'}}>
          <Box zIndex={2} w={{base: "100%", md: "25%"}} h={{base: '60vh', md: '100vh'}} overflowY={"scroll"}>
            <Menu locations={locations} />
          </Box>
          <Box h={{base: '40vh', md: '100vh'}} w={'100%'}>
            <Map locations={locations} />
          </Box>
        </Flex>
      ) : (
        <Login fetchLocations={fetchLocations} />
      )}
    </MapContextProvider>
  );
}

export default App;
