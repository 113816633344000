import { Box, Flex } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import { useContext } from "react";
import { getTimeFromTimeStamp } from "../../utils/TimeFromTimestamp";
import { MapContext } from "../Contexts/MapContext";

export const LocationsList = ({ locations }) => {
  console.log(locations[0]);

  const { mapInstance } = useContext(MapContext);
  // const [active, setActive] = useState(null);

  const centerMap = (lat, long) => {
    if (!mapInstance) return;
    mapInstance.current.flyTo([lat, long], mapInstance.current._zoom, {
      animate: true,
      duration: 2,
    });
  };

  return (
    <Box>
      {locations.map((location, index) => {
        return (
          <Box
            key={"location" + index}
            backgroundColor={"#282f3c"}
            cursor="pointer"
            border="1px"
            borderRadius={4}
            shadow="lg"
            borderColor="#394454"
            mb="3"
            p="2"
            onClick={() => {
              centerMap(location.lat, location.long);
              // setActive(location._id); // TODO: fix performance issue
            }}
          >
            <Flex justifyContent={"space-around"}>
              <Box>
                <Text fontSize="xs" fontWeight={"light"}>
                  {getTimeFromTimeStamp(location.timestamp, "DD.MM.YYYY")}
                </Text>
                <Text fontSize="md" fontWeight={"bold"}>
                  {getTimeFromTimeStamp(location.timestamp, "HH:mm:ss")}
                </Text>
              </Box>
              <Box ml="2">
                <Text fontSize="xs" fontWeight={"light"}>
                  Nopeus
                </Text>
                <Text fontSize="md" fontWeight={"bold"}>
                  {Math.floor(location.speed * 3.6)} km/h
                </Text>
              </Box>
              <Box ml="2">
                <Text fontSize="xs" fontWeight={"light"}>
                  Akku
                </Text>
                <Text fontSize="md" fontWeight={"bold"}>
                  {location.battery_level}%
                </Text>
              </Box>
            </Flex>
          </Box>
        );
      })}
    </Box>
  );
};
